/* eslint-disable no-unused-vars */
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import jwt from 'jsonwebtoken';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter, useLocation, withRouter } from 'react-router-dom';
import { ChatProvider } from '../../features/chat/hooks/use-chat';
import { AccountProvider } from '../../hooks/useAccount';
import { AccountVerifyProvider } from '../../hooks/useAccountVerify';
import { ModalLoginProvider } from '../../hooks/useModalLogin';
import { RefContext } from '../../hooks/useReferral';
import { SocketProvider } from '../../hooks/useSocket';
import ModalVerifyAccount from '../../pages/Account/components/Modal/ModalVerifyAccount';
import ActionType from '../../redux/reducer/globalActionType';
import Routing from '../../routing';
import './Root.scss';

const queryClient = new QueryClient()

function RestoreScroll(props) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return props.children;
}

const isDevelopment = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'staging'
const RestoringScroll = withRouter(RestoreScroll);

const MainPage = props => {
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1223px)' });
  useEffect(() => {
    if (isDesktopOrLaptop && !isTabletOrMobile) {
      props.setTypeDevice('dkstp');
    }
    if (isTabletOrMobile) {
      props.setTypeDevice('mbl');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktopOrLaptop, isTabletOrMobile]);

  if (props.token) {
    let decodeToken = jwt.decode(props.token);
    // isEditor = decodeToken.isLpEditor;
    props.setIsEditorLP(decodeToken.isLpEditor);
    props.setIsCSLP(decodeToken.isLpCs);
    props.setUserRole(decodeToken.role);
  }

  useEffect(() => {
    document.querySelector('body')
      .classList
      .remove('body--no-scroll');
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      {isDevelopment && (
        <ReactQueryDevtools
          initialIsOpen={false}
          position="bottom-left"
          toggleButtonProps={{ style: { color: 'green' } }}
        />
      )}
      <BrowserRouter>
        <RestoringScroll>
          <ModalLoginProvider>
            <AccountProvider>
              <AccountVerifyProvider>
                <RefContext>
                  <SocketProvider>
                    <ChatProvider>
                      <Routing />
                    </ChatProvider>
                  </SocketProvider>
                </RefContext>
                <ModalVerifyAccount />
              </AccountVerifyProvider>
            </AccountProvider>
          </ModalLoginProvider>
        </RestoringScroll>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

const mapStateToProps = state => {
  return {
    isFeedsPage: state.isFeedsPage,
    screen: state.tpdvc,
    username: state.username,
    isLogin: state.isLogin,
    chatToken: state.chatToken,
    inDiscussPage: state.dscspg,
    listDiscussConversation: state.lsdscss,
    toEmitNewMessage: state.sndnwctgrp,
    toEmitNewMessageLP: state.sndnwctgrplp,
    openDiscussLP: state.opndscslp,
    token: state.token,
    userRole: state.userrole,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setTypeDevice: type =>
      dispatch({
        type: ActionType.SET_DEVICE,
        payload: { type: type }
      }),
    setNewChatGroup: message =>
      dispatch({
        type: ActionType.NEW_CHAT_GROUP,
        payload: { message: message }
      }),
    setNewChatLP: message =>
      dispatch({
        type: ActionType.NEW_CHAT_LP,
        payload: { message: message }
      }),
    setNewEmitMessage: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_GROUP,
        payload: {
          message: message
        }
      }),
    setNewEmitMessageLP: (message) =>
      dispatch({
        type: ActionType.EMIT_NEW_CHAT_LP,
        payload: {
          message: message
        }
      }),
    setIsEditorLP: (isedtrlp) =>
      dispatch({
        type: ActionType.IS_EDITOR_LP,
        payload: {
          isedtrlp: isedtrlp
        }
      }),
    setIsCSLP: (iscslp) =>
      dispatch({
        type: ActionType.IS_CS_LP,
        payload: {
          iscslp: iscslp
        }
      }),
    setUserRole: (userrole) =>
      dispatch({
        type: ActionType.USER_ROLE,
        payload: {
          userrole: userrole
        }
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);