import { callAPI, handleResponse } from "../../../utils/call-api";
import CustomError from "../../../utils/custom-error";

export const getDetailMember = async ({ idMember, token }) => {
  return await callAPI({
    url: `/members/${idMember}`,
    method: 'get',
  }, token)
    .then(res => {
      const { success, message, data } = res
      if (!success) {
        throw new CustomError(message, data, 'getDetailMember');
      }
      return res
    })
    .catch(err => {
      throw err
    })
}

export const getUserSurveyLink = async ({ token }) => {
  return await callAPI({
    url: `/link`,
    method: 'get',
  }, token)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      throw err
    })
}

export const getBroadcastMessage = async ({ token }) => {
  const response = await callAPI({
    url: `/broadcast-message`,
    method: 'get',
  }, token)
  return response
}

export const patchProfileMember = async ({ token, memberId, data }) => {
  return await callAPI({
    url: `/members/${memberId}`,
    method: 'patch',
    data,
  }, token)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      throw err
    })
}

export const getBiodataVideoDashboard = async ({ token, params }) => {
  return await callAPI({
    url: `/video_tutorial`,
    method: 'get',
    params: {
      ...params
    },
  }, token)
    .then(res => {
      return handleResponse(res)
    })
    .catch(err => {
      throw err
    })
}

export const getCommunities = async ({ token }) => {
  try {
    const res = await callAPI({
      url: '/communities',
      method: 'get',
    }, token);

    return handleResponse(res)
  } catch (error) {
    throw error;
  }
}