import loadable from "@loadable/component";
import jwt from "jsonwebtoken";
import moment from "moment";
import React, { Suspense, useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { default as ReactGA, default as ReactGA2 } from "react-ga";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import { Route, Switch, useLocation } from "react-router-dom";
import PageLoader from "../components/PageLoader/PageLoader";
import ActionType from "../redux/reducer/globalActionType";
import { unsubscribeUser } from "../utils/notification-services";
import Middleware from "./middleware";

const FeatureCheckoutRegular = loadable(() =>
  import("../features/checkout/feature-checkout-regular")
);

const FeatureCheckoutInstant = loadable(() =>
  import("../features/checkout/feature-checkout-instant")
);

const MyVoucherPage = loadable(() =>
  import("../pages/voucher/my-voucher-page")
);
const Membership = loadable(() => import("../pages/Membership/Membership"));
const Policy = loadable(() => import("../pages/policy/policy"));
const TermsAndConditions = loadable(() => import("../pages/TermsAndConditions/TermsAndConditions"));
const Store = loadable(() => import("../pages/Store"));
const InvoiceSettlement = loadable(() =>
  import("../pages/invoice/invoice-settlement")
);
const Detail = loadable(() =>
  import("../container/pages/Orders/organims/Detail/Detail")
);
const DetailLandingPage = loadable(() =>
  import(
    "../container/pages/OrdersLandingPage/organims/Detail/DetailLandingPage"
  )
);
const Settings = loadable(() => import("../container/pages/Settings"));
const FeatureUpdatePage = loadable(() => import("../pages/feature-update"));
const VideoTutorialSystem = loadable(() =>
  import("../container/pages/VideoTutorialSystem")
);
const ExpiredDomains = loadable(() =>
  import("../container/pages/MyLandingPageDomain/organisms/ExpiredDomains")
);
const Feeds = loadable(() => import("../container/pages/Feeds"));
const BulkOrders = loadable(() => import("../container/pages/BulkOrders"));
const PaylaterPayment = loadable(() =>
  import("../container/pages/Paylater/PaylaterPayment")
);
const PaylaterPaymentHistory = loadable(() =>
  import("../container/pages/Paylater/PaylaterPaymentHistory")
);
const PaylaterDashboard = loadable(() =>
  import("../container/pages/Paylater/PaylaterDashboard")
);
const PaylaterInvoiceConfirm = loadable(() =>
  import("../container/pages/Paylater/PaylaterInvoiceConfirm")
);
const MitraOrders = loadable(() => import("../container/pages/MitraOrders"));
const Mitra = loadable(() => import("../container/pages/Mitra"));
// const LandingPageSupplierV2 = loadable(() => import('../container/pages/LandingPageSupplierV2'));
const ProductRecomendation = loadable(() =>
  import("../container/pages/ProductRecomendation")
);
const ProductBestSelling = loadable(() =>
  import("../pages/Product/product-best-selling-page")
);
const HomePage = loadable(() => import("../container/pages/HomePage/HomePage"));
const Community = loadable(() =>
  import("../container/pages/Community/Community")
);
// const Account = loadable(() => import("../container/pages/Account/Account"));
const Account = loadable(() => import("../pages/Account/Account"));
const Product = loadable(() => import("../container/pages/Product/Product"));
const Orders = loadable(() => import("../container/pages/Orders/Orders"));
const OrdersLandingPage = loadable(() =>
  import("../container/pages/OrdersLandingPage/OrdersLandingPage")
);
// const MultiBuy = loadable(() => import("../container/pages/MultiBuy/MultiBuy"));
const MultiBuy = loadable(() => import("../pages/multi-buy/index"));
// const ProductsByCategory = loadable(() =>
//   import("../container/pages/ProductsByCategory/ProductsByCategory")
// );
const ProductsByCategory = loadable(() =>
  import("../pages/Product/search/product-by-category")
);
// const ProductsBySearch = loadable(() =>
//   import("../container/pages/ProductsBySearch/ProductsBySearch")
// );
const ProductsBySearch = loadable(() =>
  import("../pages/Product/search/product-by-search")
);
const Login = loadable(() => import("../container/pages/Login/Login"));
const LandingDropshipper = loadable(() =>
  import("../container/pages/LandingPage/Dropshipper/Dropshipper")
);
// const LandingLP = loadable(() => import('../container/pages/LandingPage/LandingPage/LandingPage'));
// const Checkout = loadable(() => import("../container/pages/Checkout/Checkout"));
const Register = loadable(() => import("../pages/Register/Register"));
const RegisterInvoice = loadable(() =>
  import("../container/pages/RegisterInvoice/RegisterInvoice")
);
const Invoice = loadable(() => import("../container/pages/Invoice/Invoice"));
const ConfirmInvoice = loadable(() =>
  import("../container/pages/ConfirmInvoice/ConfirmInvoice")
);
const ForgotPassword = loadable(() =>
  import("../container/pages/ForgotPassword/ForgotPassword")
);
const ResetPassword = loadable(() =>
  import("../container/pages/ResetPassword/ResetPassword")
);
const Wallet = loadable(() => import("../container/pages/Wallet/Wallet"));
const VideoTutorial = loadable(() =>
  import("../container/pages/VideoTutorial/VideoTutorial")
);
const VideoTutorialV2 = loadable(() =>
  import("../pages/VideoTutorial/VideoTutorialV2")
);
const Notification = loadable(() =>
  import("../container/pages/Notification/Notification")
);
const Discuss = loadable(() => import("../container/pages/Discuss/Discuss"));
const VoucherStore = loadable(() =>
  import("../container/pages/VoucherStore/VoucherStore")
);
const VoucherDetail = loadable(() =>
  import("../container/pages/VoucherDetail/VoucherDetail")
);
const VoucherCheckout = loadable(() =>
  import("../container/pages/VoucherCheckout/VoucherCheckout")
);
const Scrape = loadable(() => import("../container/pages/Scrape/Scrape"));
// const CheckoutInstant = loadable(() =>
//   import("../container/pages/CheckoutInstant/CheckoutInstant")
// );
// const MyLandingPage = loadable(() => import('../container/pages/MyLandingPage/MyLandingPage'));
// const MyLandingPageRegister = loadable(() => import('../container/pages/MyLandingPageRegister/MyLandingPageRegister'));
// const MyLandingPageDomain = loadable(() => import('../container/pages/MyLandingPageDomain/MyLandingPageDomain'));
// const RegisterBundle = loadable(() => import('../container/pages/RegisterBundle/RegisterBundle'));
const InvoiceLP = loadable(() =>
  import("../container/pages/InvoiceLP/InvoiceLP")
);
const ConfirmInvoiceLP = loadable(() =>
  import("../container/pages/ConfirmInvoiceLP/ConfirmInvoiceLP")
);
// const DiscussOrderLP = loadable(() => import('../container/pages/DiscussOrderLP/DiscussOrderLP'));
const LogoutAllDevice = loadable(() =>
  import("../container/pages/LogoutAllDevice/LogoutAllDevice")
);
const DashboardRTS = loadable(() =>
  import("../container/pages/DashboardRTS/DashboardRTS")
);
const HistoryPayRTS = loadable(() =>
  import("../container/pages/HistoryPayRTS/HistoryPayRTS")
);
const PayRTS = loadable(() => import("../container/pages/PayRTS/PayRTS"));
// const SubmissionRTS = loadable(() => import('../container/pages/SubmissionRTS/SubmissionRTS'));
const ConfirmInvoiceRTS = loadable(() =>
  import("../container/pages/ConfirmInvoiceRTS/ConfirmInvoiceRTS")
);
const ApplyRTS = loadable(() => import("../container/pages/ApplyRTS/ApplyRTS"));
const ForbiddenAccess = loadable(() =>
  import("../container/pages/ForbiddenAccess/ForbiddenAccess")
);
const ListingSupplier = loadable(() =>
  import("../pages/listing-supplier-page")
);
const Webinar = loadable(() => import("../container/pages/Webinar/Webinar"));
const PrivacyPolicy = loadable(() =>
  import("../container/pages/PrivacyPolicy")
);
const ErrorPage = loadable(() =>
  import("../container/pages/ErrorPage/ErrorPage")
);
// const DiscussOrderLP2 = loadable(() => import('../container/pages/DiscussOrderLP2'));
const DetailPaymentRTS = loadable(() =>
  import("../container/pages/DetailPaymentRTS/DetailPaymentRTS")
);
const RegisterSupplier = loadable(() =>
  import("../pages/RegisterSupplier")
);
const FaqNew = loadable(() => import("../container/pages/FaqNew"));
// const MyVoucher = loadable(() => import("../container/pages/MyVoucher"));
const MPReportSeller = loadable(() =>
  import("../container/pages/ReportSeller/MPReportSeller")
);
const PBReportSeller = loadable(() =>
  import("../container/pages/ReportSeller/PBReportSeller")
);
const DashboardMarketplace = loadable(() =>
  import("../container/pages/Dashboard/DashboardMarketplace")
);
const DashboardLandingpage = loadable(() =>
  import("../container/pages/Dashboard/DashboardLandingpage")
);
const RecordedWebinar = loadable(() =>
  import("../pages/RecordedWebinar/RecordedWebinar")
);
const RecordedWebinarDetail = loadable(() =>
  import("../pages/RecordedWebinar/RecordedWebinarDetail")
);

const TRACKING_ID = "UA-178435587-1"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

const TRACKING_ID2 = "AW-10868522454"; // OUR_TRACKING_ID
ReactGA2.initialize(TRACKING_ID2);

const FACEBOOK_ID = "511534716600280";
const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false, // enable logs
};

ReactPixel.init(FACEBOOK_ID, null, options);

const Routing = (props) => {
  const location = useLocation();
  let history = useHistory();
  const { setIsFeedsPage } = props;

  const handleUnsubscribe = async () => {
    await unsubscribeUser();
  };

  useEffect(() => {
    if (props.token) {
      const exp = jwt.decode(props.token).exp;
      const current = moment().unix();

      if (exp && current) {
        if (exp < current) {
          props.logout();
          handleUnsubscribe();
          history.push("/");
        }
      }
    }
  }, [location, props.token]); // eslint-disable-line

  useEffect(() => {
    const page = window.location.pathname;
    setIsFeedsPage(Boolean(page.match(/^\/feeds/g)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA2.pageview(window.location.pathname + window.location.search);
    ReactPixel.pageView();
  }, [location]);

  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Route
          exact
          path="/"
          component={(props) => (
            <HomePage fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/marketplace"
          component={(props) => (
            <HomePage fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/dropshipper"
          component={(props) => (
            <LandingDropshipper fallback={<PageLoader />} {...props} />
          )}
        />
        {/* <Route exact path="/supplier" component={LandingPageSupplierV2} /> */}
        {/* <Route exact path="/page-builder" component={LandingLP} /> */}
        <Route exact path="/jadi-supplier" component={RegisterSupplier} />
        <Route
          exact
          path="/login"
          component={(props) => <Login fallback={<PageLoader />} {...props} />}
        />
        <Route
          exact
          path="/faq"
          component={(props) => <FaqNew fallback={<PageLoader />} {...props} />}
        />
        <Route
          exact
          path="/feature-update"
          component={(props) => (
            <FeatureUpdatePage fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/community"
          component={(props) => (
            <Community fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          path="/register"
          component={(props) => (
            <Register fallback={<PageLoader />} {...props} />
          )}
        />
        {/* <Route exact path="/register-bundle" component={RegisterBundle} /> */}
        <Route
          exact
          path="/inv-reg/confirm/:id"
          component={(props) => (
            <RegisterInvoice fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/forgot-password"
          component={(props) => (
            <ForgotPassword fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/reset-password"
          component={(props) => (
            <ResetPassword fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/invoice-lp-confirm/:id"
          component={(props) => (
            <ConfirmInvoiceLP fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/log-out-all-devices"
          component={(props) => (
            <LogoutAllDevice fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/forbidden-access"
          component={(props) => (
            <ForbiddenAccess fallback={<PageLoader />} {...props} />
          )}
        />
        {/* Disable Discuss */}
        <Route exact path="/discuss" component={Discuss} />
        <Route exact path="/discuss/:id" component={Discuss} />
        {/* <Route exact path='/discuss-order-lp/detail/:id' component={DiscussOrderLP} /> */}
        {/* <Route exact path='/discuss-order-lp' component={DiscussOrderLP2} /> */}
        {/* <Route exact path='/discuss-order-lp/:id' component={DiscussOrderLP2} /> */}
        <Route
          exact
          path="/seller"
          component={() => {
            window.location.href = "https://dash.baleomol.com/seller";
            return null;
          }}
        />
        <Route
          exact
          path="/admstr"
          component={() => {
            window.location.href = "https://dash.baleomol.com/admstr";
            return null;
          }}
        />
        <Route exact path="/listing-supplier" component={ListingSupplier} />
        <Route path="/mitra" component={Mitra} />
        <Route
          exact
          path="/privacy-policy"
          component={(props) => (
            <PrivacyPolicy fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          exact
          path="/policy/:slug?"
          component={(props) => <Policy fallback={<PageLoader />} {...props} />}
        />
        <Route
          exact
          path="/terms-and-conditions/:slug?"
          component={(props) => <TermsAndConditions fallback={<PageLoader />} {...props} />}
        />
        {/* <Route
          exact
          path="/register-whatsapp"
          component={(props) => (
            <RegisterWhatsapp fallback={<PageLoader />} {...props} />
          )}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/webinar"
          component={Webinar}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/webinar/recorded"
          component={RecordedWebinar}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/webinar/:videoId/play"
          component={RecordedWebinarDetail}
        />
        <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/store"
          component={Store}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/notification/:type"
          component={Notification}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={true}
          path="/category/:id/:page?"
          component={ProductsByCategory}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={true}
          path="/search"
          component={ProductsBySearch}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/cart"
          component={MultiBuy}
        />
        <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/checkout"
          component={FeatureCheckoutRegular}
        />
        {/* <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/v1/checkout"
          component={(props) => (
            <Checkout fallback={<PageLoader />} {...props} />
          )}
        /> */}
        <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/checkout-instant"
          component={FeatureCheckoutInstant}
        />
        {/* <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/v2/checkout-instant"
          component={FeatureCheckoutInstant}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/invoice/:id"
          component={Invoice}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/invoice-confirm/:id"
          component={ConfirmInvoice}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/invoice-settlement"
          component={InvoiceSettlement}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/invoice-lp/:id"
          component={InvoiceLP}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/orders/:slug/:page"
          component={Orders}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/order/report-seller/:identifier"
          component={MPReportSeller}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/order-landing-page/report-seller/:identifier"
          component={PBReportSeller}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/orders-landing-page/:slug/:page"
          component={OrdersLandingPage}
        />
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/landing-page"
          component={MyLandingPage}
        /> */}
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/landing-page-register"
          component={MyLandingPageRegister}
        /> */}
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/landing-page-domains"
          component={MyLandingPageDomain}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/extend-landing-page-domains"
          component={ExpiredDomains}
        />
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/landing-page-domains/:path"
          component={MyLandingPageDomain}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/order/detail/:id"
          component={Detail}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          csAccess
          path="/order-landing-page/detail/:id"
          component={DetailLandingPage}
        />
        <Route path="/settings/*" component={Settings} />
        <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/membership"
          component={Membership}
        />
        <Middleware
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/account"
          component={Account}
        />

        <Middleware
          exact
          authed={props.isLogin}
          role={
            typeof props.userRole !== "undefined" ? props.userRole : "anonymous"
          }
          editorAccess={true}
          csAccess
          path="/product/:id/:link?"
          component={Product}
        />
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={
            typeof props.userRole !== "undefined" ? props.userRole : "anonymous"
          }
          editorAccess={true}
          csAccess
          path="/product-v2/:id/:link"
          component={ProductV2}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/wallet/:page"
          component={Wallet}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/video-tutorial/:type"
          component={VideoTutorial}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/video-tutorial-system"
          component={VideoTutorialSystem}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/video-tutorial-affiliate"
          component={VideoTutorialV2}
        />
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/my-voucher/:page"
          component={MyVoucher}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/my-voucher/:page"
          component={MyVoucherPage}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/voucher-store"
          component={VoucherStore}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/voucher/:slug"
          component={VoucherDetail}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/checkout-voucher"
          component={VoucherCheckout}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/dashboard/marketplace"
          component={DashboardMarketplace}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/dashboard/landingpage"
          component={DashboardLandingpage}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/scrape"
          component={(props) => <Scrape fallback={<PageLoader />} {...props} />}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/dashboard-rts"
          component={DashboardRTS}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/rts/history-pay"
          component={HistoryPayRTS}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/rts/apply"
          component={ApplyRTS}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/pay-rts"
          component={(props) => <PayRTS fallback={<PageLoader />} {...props} />}
        />
        {/* <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path='/rts/submission'
          component={SubmissionRTS}
        /> */}
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/inv-rts/confirm/:id"
          component={ConfirmInvoiceRTS}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/rts/payment/:id"
          component={DetailPaymentRTS}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/feeds"
          component={(props) => <Feeds fallback={<PageLoader />} {...props} />}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/bulk-order/:slug/:page"
          component={BulkOrders}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/mitra-order/:slug/:page"
          component={MitraOrders}
        />

        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/paylater/dashboard"
          component={PaylaterDashboard}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/paylater/payment-history"
          component={PaylaterPaymentHistory}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/paylater/payment"
          component={PaylaterPayment}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/paylater/invoice/confirm/:code"
          component={PaylaterInvoiceConfirm}
        />
        <Middleware
          exact
          authed={props.isLogin}
          role={props.userRole}
          editorAccess={false}
          path="/product-recommendation/:slug?"
          component={ProductRecomendation}
        />
        <Route
          path="/product-best-selling"
          component={(props) => (
            <ProductBestSelling fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          path="*"
          component={(props) => (
            <ErrorPage fallback={<PageLoader />} {...props} />
          )}
        />
        <Route
          path="/404"
          component={(props) => (
            <ErrorPage fallback={<PageLoader />} {...props} />
          )}
        />
      </Switch>
    </Suspense>
  );
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.isLogin,
    deviceType: state.tpdvc,
    screen: state.tpdvc,
    token: state.token,
    isEditorLP: state.isedtrlp,
    isCSLP: state.iscslp,
    userRole: state.userrole,
    // template: state.template
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch({ type: ActionType.LOGOUT }),
    setIsFeedsPage: (isFeeds) =>
      dispatch({
        type: ActionType.SET_IS_FEEDS_PAGE,
        payload: isFeeds,
      }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routing);
