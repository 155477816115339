import React from "react";
import styled from "@emotion/styled";
import Lottie from "react-lottie";
import LoaderLottieData from "../../assets/lotties/Page-Loader.json";

const Wrapper = styled.div`
  width: 100%;
  min-width: 100dvw;
  margin: 0 auto;
  height: 100%;
  min-height: 100dvh;
  display: flex;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;

  --svg-width: 175px;
  --svg-height: 175px;

  & > div[role="button"] {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (min-width: 768px) {
    --svg-width: 200px;
    --svg-height: 200px;
  }

  @media screen and (min-width: 992px) {
    --svg-width: 210px;
    --svg-height: 210px;
  }

  svg {
    width: var(--svg-width) !important;
    height: var(--svg-width) !important;
  }
`;

const loaderLottieOptions = {
  loop: true,
  autoplay: true,
  animationData: LoaderLottieData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const PageLoader = () => (
  <Wrapper>
    <Lottie options={loaderLottieOptions} isClickToPauseDisabled />
  </Wrapper>
);

export default PageLoader;
