import { memo } from "react";
import { Redirect, Route } from "react-router-dom";
import PageLoader from '../components/PageLoader/PageLoader';

// const Middleware = ({
//   component: Component,
//   authed,
//   editorAccess,
//   role,
//   csAccess = false,
//   ...rest
// }) => {
//   return (
//     <>
//       {/* Anonymous */}
//       {role === "anonymous" && (
//         <>
//           <Route {...rest} render={(props) => <Component {...props} />} />
//         </>
//       )}

//       {/* Editor */}
//       {role === "lp_editor" && (
//         <>
//           <Route
//             {...rest}
//             render={(props) =>
//               editorAccess && authed ? (
//                 <Component {...props} />
//               ) : (
//                 <Redirect
//                   to={{
//                     pathname: "/forbidden-access",
//                     state: { from: props.location },
//                   }}
//                 />
//               )
//             }
//           />
//         </>
//       )}

//       {/* CS */}
//       {role === "lp_cs" && (
//         <>
//           <Route
//             {...rest}
//             render={(props) =>
//               csAccess && authed ? (
//                 <Component {...props} />
//               ) : (
//                 <Redirect
//                   to={{
//                     pathname: "/forbidden-access",
//                     state: { from: props.location },
//                   }}
//                 />
//               )
//             }
//           />
//         </>
//       )}

//       {/* Member */}
//       {role === "member" && (
//         <Route
//           {...rest}
//           render={(props) =>
//             authed ? (
//               <Component {...props} />
//             ) : (
//               <Redirect
//                 to={{ pathname: "/login", state: { from: props.location } }}
//               />
//             )
//           }
//         />
//       )}

//       {role !== "anonymous" && typeof authed === "undefined" && (
//         <Route
//           {...rest}
//           render={(props) => (
//             <Redirect
//               to={{ pathname: "/login", state: { from: props.location } }}
//             />
//           )}
//         />
//       )}
//     </>
//   );
// };

const Middleware = ({
  component: Component,
  authed = false,
  editorAccess,
  role,
  csAccess = false,
  ...rest
}) => {
  const renderComponent = (props) => <Component fallback={<PageLoader />} {...props} />;

  const redirectToLogin = (props) => (
    <Redirect
      to={{ pathname: "/login", state: { from: props.location } }}
    />
  );

  const redirectToForbidden = (props) => (
    <Redirect
      to={{ pathname: "/forbidden-access", state: { from: props.location } }}
    />
  );

  if (role === "anonymous") {
    return <Route {...rest} render={renderComponent} />;
  }

  if (role === "lp_editor") {
    return (
      <Route
        {...rest}
        render={(props) =>
          editorAccess && authed ? renderComponent(props) : redirectToForbidden(props)
        }
      />
    );
  }

  if (role === "lp_cs") {
    return (
      <Route
        {...rest}
        render={(props) =>
          csAccess && authed ? renderComponent(props) : redirectToForbidden(props)
        }
      />
    );
  }

  if (role === "member") {
    return (
      <Route
        {...rest}
        render={(props) => (authed ? renderComponent(props) : redirectToLogin(props))}
      />
    );
  }

  return (
    <Route
      {...rest}
      render={role !== "anonymous" && !authed ? redirectToLogin : renderComponent}
    />
  );
};

export default memo(Middleware);