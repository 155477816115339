export const setLocalStorageValue = (key, value) => {
  try {
    window.localStorage.setItem(
      key,
      JSON.stringify(value)
    )
  } catch (error) {
    alert('Something went wrong')
  }
}

export const getLocalStorageValue = (key) => {
  try {
    const value = window.localStorage.getItem(key)
    return value ? JSON.parse(value) : null
  } catch (error) {
    alert('Something went wrong')
  }
}

export const useLocalStorage = () => {
  return { setLocalStorageValue, getLocalStorageValue }
}
